exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-boucle-studio-tsx": () => import("./../../../src/pages/boucleStudio.tsx" /* webpackChunkName: "component---src-pages-boucle-studio-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kampanie-modowe-tsx": () => import("./../../../src/pages/kampanieModowe.tsx" /* webpackChunkName: "component---src-pages-kampanie-modowe-tsx" */),
  "component---src-pages-oferta-sesje-wizerunkowe-biznesowe-kobiece-tsx": () => import("./../../../src/pages/oferta/sesje-wizerunkowe-biznesowe-kobiece.tsx" /* webpackChunkName: "component---src-pages-oferta-sesje-wizerunkowe-biznesowe-kobiece-tsx" */),
  "component---src-pages-oferta-video-reelsy-tsx": () => import("./../../../src/pages/oferta/video-reelsy.tsx" /* webpackChunkName: "component---src-pages-oferta-video-reelsy-tsx" */),
  "component---src-pages-oferta-voucher-prezentowy-tsx": () => import("./../../../src/pages/oferta/voucher-prezentowy.tsx" /* webpackChunkName: "component---src-pages-oferta-voucher-prezentowy-tsx" */),
  "component---src-pages-sesje-wizerunkowe-tsx": () => import("./../../../src/pages/sesjeWizerunkowe.tsx" /* webpackChunkName: "component---src-pages-sesje-wizerunkowe-tsx" */)
}

